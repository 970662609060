var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "icon-wrapper",
      class: "icon-wrapper--" + _vm.type,
      style: "" + _vm.modifiedIconWrapperSize,
    },
    [
      _c("img", {
        style: "" + _vm.modifiedIconSize,
        attrs: {
          src: require("@/assets/images/vega/" + _vm.modifiedType + ".svg"),
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }