<template>
  <div class="icon-wrapper" :style="`${modifiedIconWrapperSize}`" :class="`icon-wrapper--${type}`">
    <img :src="require(`@/assets/images/vega/${modifiedType}.svg`)" :style="`${modifiedIconSize}`" />
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String
    },
    width: {
      type: Number
    }
  },

  computed: {
    modifiedType() {
      if (!this.type) {
        return ''
      }

      return this.type.replaceAll(' ', '').toLowerCase()
    },

    modifiedIconWrapperSize() {
      if (!this.width) {
        return ''
      }

      return `width: ${this.width}px; min-width: ${this.width}px; height: ${this.width}px;`
    },

    modifiedIconSize() {
      if (!this.width) {
        return ''
      }

      return `width: ${this.width / 1.81}px; height: ${this.width / 1.81}px;`
    },
  }
}
</script>

<style lang="scss" scoped>
.icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;

  &--onfire {
    background: rgba(254, 18, 78, 0.62);
  }
  &--hot {
    background: rgba(224, 0, 0, 0.3);
  }
  &--warm {
    background: #54f23e;
  }
  &--mild {
    background: #33f49c;
  }
  &--cold {
    background: #0ed1d1;
  }
  &--frozen {
    background: #08a4e8;
  }
}
</style>
